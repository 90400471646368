import React, { useEffect, useState } from 'react';
import db from "../Firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DatePicker from "react-datepicker";
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import { isDisabled } from '@testing-library/user-event/dist/utils';

const AddVacationDialog = ({ onClose, customerDetails , subscriptionData}) => {
    const moment = extendMoment(Moment);
    const [showDatePickers, setShowDatePickers] = useState(true);

    const tomorrow = moment().add(1, 'day').toDate();
    const [startDate, setStartDate] = useState(tomorrow);
    const [endDate, setEndDate] = useState(tomorrow);
    const [overlapFound, setOverlapFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disableAddVacation , setDisableAddVacation] = useState(false);
    const [loading , setLoading] = useState(false);


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

//     const updateNextDeliveryDateForAllSubscription = async (vacation_end_date) => {
//         debugger;
//         const next_delivery_date = new Date(vacation_end_date);
//         next_delivery_date.setDate(next_delivery_date.getDate() + 1);
        
//         for(const subscription of subscriptionData) {

//             const docId = subscription.id; 
//             const docRef = db.collection('subscriptions_data').doc(docId);
//             if(subscription.start_date.toDate() > next_delivery_date) { 
//                 await docRef.update({
//                     next_delivery_date: moment(subscription.start_date.toDate()).format('YYYY-MM-DD')
//                 });
//             }else {
//                 await docRef.update({
//                     next_delivery_date: moment(next_delivery_date).format('YYYY-MM-DD')
//                 });
//             }
            
            
//         }
// }

const fetchVacationsCount = async (customerId) => {
    try {
        const collRef = collection(db, 'customers_vacation');
        const q = query(collRef, where('customer_id', '==', customerId))
        const snapshot = await getCountFromServer(q);
        
        return snapshot.data().count

    } catch (error) {
        console.error("Error fetching vacation count: ", error);
    }
};

    const handleSubmitVacation = async (e) => {
        debugger;
        e.preventDefault();
        setDisableAddVacation(true);
        setLoading(true);
        //const today = new Date();
        //today.setHours(0,0,0,0);

        try {
            if (!startDate || !endDate) {
                setErrorMessage('Please enter all fields.');
                setDisableAddVacation(false);
                setLoading(false);
                return;
            }

            const vacation_count = await fetchVacationsCount(customerDetails.customer_id);

            if(vacation_count > 0) {
                alert("Please the delete the existing vacation & Add new again");
                setDisableAddVacation(false);
            }else {
                await db.collection('customers_vacation').add({
                    'start_date': new Date(startDate),
                    'end_date': new Date(endDate),
                    'source': 'website',
                    'customer_phone': customerDetails.customer_phone,
                    'customer_id': customerDetails.customer_id,
                    'customer_name': customerDetails.customer_name,
                    'updated_date': new Date(),
                    'created_date': new Date(),
                });
                setStartDate(null);
                setEndDate(null);
                setOverlapFound(false);
                // updateNextDeliveryDateForAllSubscription(new Date(endDate));
                db.collection("customer_activities").add({
                                        'customer_phone': customerDetails.customer_phone,
                                        'customer_id': customerDetails.customer_id,
                                        'customer_name': customerDetails.customer_name,
                                        'customer_address': customerDetails.customer_name,
                                        'hub_name': customerDetails.hub_name,
                                        'delivery_exe_id': customerDetails.delivery_exe_id,
                                        'user': customerDetails.customer_name,
                                        'object': "Add Vacation",
                                        'action': "Add Vacation",
                                        'description': `Vacation has been added through website with start date ${moment(startDate).format('DD-MM-YYYY')} and end date ${moment(endDate).format('DD-MM-YYYY')} by ${customerDetails.customer_name}`,
                                        'platform':'webApp',
                                        'date': new Date(),
                                        'created_date': new Date()
                                    }).then(() => {
                                        
                                    })
                
                    setDisableAddVacation(false);
                    setLoading(false);
                    onClose();
                    
            }                
        } catch (error) {
            console.error('Error checking or adding vacation:', error);
            setErrorMessage('Error checking or adding vacation. Please try again.');
        }
    };

    return (
        <div className="dialog-overlay">
            {loading && (
                <div className="loader-overlay">
                    <div className="">
                        <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loading..." />
                    </div>
                </div>
            )}
            <div className="dialog-box">
                <div className="dialog-header">Add Vacation</div>
                <div className="dialog-content">
                    <main>
                        <div>
                            <div>
                                <form onSubmit={handleSubmitVacation}>
                                    <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between' , width:'80%'}}>
                                        <div>
                                        <label>Start Date: </label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={tomorrow}
                                            required
                                        />
                                        </div>
                                        <div>
                                        <label style={{marginLeft:'1rem'}}>End Date: </label>
                                        <DatePicker 
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            dateFormat="dd/MM/yyyy"
                                            required
                                        />
                                        </div>
                                        <div>
                                        <button type="submit" className="subscribe-button" style={{backgroundColor:'black' , marginLeft:'1rem'}} disabled={disableAddVacation}>Add Vacation</button>
                                        <button className="subscribe-button" onClick={onClose} style={{marginLeft:'1rem'}}>Close</button>
                                        </div>
                                    </div>
                                    <div>
                                        
                                        

                                        

            
            
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddVacationDialog;
