import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import db from "../Firebase";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ProductDetail.css';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

const ProductModal = ({loadModal , subscription}) => {
  const [show, setShow] = useState(loadModal);
  const moment = extendMoment(Moment);
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState("One-Time");
    const [quantity, setQuantity] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customDays, setCustomDays] = useState({
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0
    });
    const [intervalDays, setIntervalDays] = useState(2);
    const [nextDeliveryDate, setNextDeliveryDate] = useState('');
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [showOnInterval, setShowOnInterval] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [activeFrequency, setActiveFrequency] = useState('One-Time');

    const incrementQuantity = () => setQuantity(quantity + 1);
    const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    const incrementInterval = () => setIntervalDays(intervalDays + 1);
    const decrementInterval = () => setIntervalDays(intervalDays > 2 ? intervalDays - 1 : 2);

    const handleFrequencyClick = (frequency) => {
        setActiveFrequency(frequency);
        setSubscriptionType(frequency);

        setShowDatePickers(frequency !== "One-Time");
        setShowOnInterval(frequency === "On-Interval");
        setShowCustom(frequency === "Custom");
    };

    // useEffect(() => {
    //     const today = new Date();
    //     const tomorrow = new Date(today);
    //     tomorrow.setDate(today.getDate() + 1);
    //     const nextYear = new Date(today);
    //     nextYear.setFullYear(today.getFullYear() + 1);

    //     setStartDate(tomorrow.toISOString().split('T')[0]);
    //     setEndDate(nextYear.toISOString().split('T')[0]);
    //     setNextDeliveryDate(tomorrow.toISOString().split('T')[0]);
    // }, []);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };
    
        useEffect(() => {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            const nextYear = new Date(today);
            nextYear.setFullYear(today.getFullYear() + 1);
    
            setStartDate(formatDate(tomorrow));
            //setEndDate(formatDate(nextYear));
            setNextDeliveryDate(formatDate(tomorrow));
            
        }, []);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const doc = await db.collection('products_data').doc(id).get();
                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    setSelectedPackage(productData.packagingOptions[0]);
                } else {
                    
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchProductDetails();
    }, [id]);

    const addToCart = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const productToAdd = {
            productName: product.productName,
            image: product.image,
            price: parseInt(selectedPackage.price),
            package_unit: selectedPackage.packaging + ' ' + selectedPackage.pkgUnit,
            subscriptionType: subscriptionType,
            quantity: parseInt(quantity),
            startDate: new Date(startDate),
            endDate: endDate === '' ? '3000-01-01': new Date(endDate),
            sunday: parseInt(customDays.sunday) || 0,
            monday: parseInt(customDays.monday) || 0,
            tuesday: parseInt(customDays.tuesday) || 0,
            wednesday: parseInt(customDays.wednesday) || 0,
            thursday: parseInt(customDays.thursday) || 0,
            friday: parseInt(customDays.friday) || 0,
            saturday: parseInt(customDays.saturday) || 0,
            interval: subscriptionType === 'On-Interval' ? parseInt(intervalDays) : subscriptionType === "Everyday" ? 1 : 0,
            next_delivery_date: nextDeliveryDate,
            reason: ""
        };
        cart.push(productToAdd);
        localStorage.setItem('cart', JSON.stringify(cart));
        navigate('/cart');
    };

    const handleQuantityChange = (increment) => {
        setQuantity((prevQuantity) => {
            const newQuantity = increment ? prevQuantity + 1 : prevQuantity - 1;
            return newQuantity > 0 ? newQuantity : 1;
        });
    };

    const handleCustomDayChange = (day, value) => {
        setCustomDays(prevState => ({
            ...prevState,
            [day]: value
        }));
    };

    // if (!product) {
    //     console.log("load modal value => " , loadModal);
    //     return <div>Loading...</div>;
    // }

    const handleEndDate = (e) => {
        if(e.target.value === '') {
            setEndDate('3000-01-01');
        }else {
            setEndDate(e.target.value);
            
        }
        //(e) => 
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  

  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Open Modal
      </Button> */}

      <Modal show={loadModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <main>
        <Navbar/>
        <div className="product-detail-container">
            <div className="product-detail-card">
                <div className="product-detail-left">
                    <img src={subscription.image} className="img-fluid rounded-start product-detail-page-image" alt={product.productName} style={{ height: "30rem" }} />
                </div>
                <div className="product-detail-right">
                    <h2>{subscription.productName}</h2>                               
                    
                    <div className="frequency-buttons">
                        {product.packagingOptions.map(pkg => (
                            <button
                                key={pkg.packaging}
                                className="frequency-button"
                                onClick={() => setSelectedPackage(pkg)}
                            >
                                {pkg.packaging} {pkg.pkgUnit}
                            </button>
                        ))}
                    </div>
                    <p className="product-price">₹{selectedPackage.price}</p>
                    <div className="product-description">
                        <p>{product.productDescription}</p>
                    </div>
                    <p className="order-info">
                        Ordered by 11:00 PM Today & get the delivery by <strong>{moment(nextDeliveryDate).format('MMMM D, YYYY')}</strong>
                    </p>
                    <p>Frequently</p>
                    <div className="frequency-buttons">
                        {['One-Time','Everyday',  'Custom', 'On-Interval'].map(frequency => (
                            <button
                                key={frequency}
                                className={`frequency-button ${activeFrequency === frequency ? 'active' : ''}`}
                                onClick={() => handleFrequencyClick(frequency)}
                            >
                                {frequency}
                            </button>
                        ))}
                    </div>
                    {!showCustom && (<>
                        <p style={{ marginTop: '20px' , fontSize:'18px' , fontWeight:'600'}} className='theme-color'>Quantity</p>
                    <div className="quantity-selector">
                        <button onClick={decrementQuantity}>-</button>
                        <span>{quantity}</span>
                        <button onClick={incrementQuantity}>+</button>
                    </div>
                    </>) }

                    {showOnInterval && (
                        <>

                            <div style={{display:'flex' , flexDirection:'row'}}>
                            <div className="quantity-selector">
                                <div>
                                <p className='theme-color' style={{fontSize:'16px'}}>Every</p>
                                </div>
                            
                            <div style={{marginLeft:'4px'}}>
                                <button onClick={decrementInterval}>-</button>
                                <Badge pill bg="" style={{backgroundColor: '#e1a95f' , marginTop:'5px'}}>{intervalDays}</Badge>
                                <button onClick={incrementInterval}>+</button>
                            </div>
                            <div>
                            <p className='theme-color' style={{fontSize:'16px' , marginLeft:'10px'}}>Day(s)</p>
                            </div>
                            <div>
                            
                            </div>
                            
                            </div>
                            {/* <div class="dropdown theme-color"  style={{marginLeft:'10px' , marginTop:'10px'}}>
                                <DropdownButton id="dropdown-item-button" title="Dropdown button">
                                    <Dropdown.Item as="button">Action</Dropdown.Item>
                                    <Dropdown.Item as="button">Another action</Dropdown.Item>
                                    <Dropdown.Item as="button">Something else</Dropdown.Item>
                                </DropdownButton>
                                                                        
                            </div> */}
                            
                            </div>
                            
                            
                            
                        </>
                    )}
                    
                    {showCustom && (
                        <div className="custom-days" style={{display: "ruby-text"}}>
                            {Object.keys(customDays).map((day) => (
                                <div key={day} className="form-group">
                                    <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id={day}
                                        value={customDays[day]}
                                        onChange={(e) => handleCustomDayChange(day, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {showDatePickers ? (
                        <div className="row">
                            <div className="col-6 mt-4">
                                <h5 style={{ fontSize: "12px" }}>Starts On</h5>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-6 mt-4">
                                <h5 style={{ fontSize: "12px" }}>Ends On</h5>
                                <input type="date" className="form-control" value={endDate} onChange={handleEndDate} />
                            </div>
                        </div>
                    ):(

                        <div className="row">
                            <div className="col-6 mt-4">
                                <h5 style={{ fontSize: "12px" }}>Delivery On</h5>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                        </div>
                    )}
                    <button className="subscribe-button" style={{ marginTop: '10px', fontSize: 'small' , backgroundColor:'black' }} onClick={addToCart}>Add To Cart</button>
                </div>
            </div>
        </div>
        </main>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductModal;
