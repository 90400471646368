// import React, { useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../context/AuthContext';
// import db from "../Firebase";

// function Otp() {

//     const [digit1 , setdigit1] = useState('');
//     const [digit2 , setdigit2] = useState('');
//     const [digit3 , setdigit3] = useState('');
//     const [digit4 , setdigit4] = useState('');
//     const [otp, setOtp] = useState('');

//     const handleOtpChange = (e) => {
//         const value = e.target.value;

//         if(e.target.name == "d1") {
//             setdigit1(e.target.value);
//         }else if(e.target.name == "d2") {
//             setdigit2(e.target.value)
//         }else if(e.target.name== 'd3') {
//             setdigit3(e.target.value);
//         }else if(e.target.name == 'd4') {
//             setdigit4(e.target.value);
//         }

//         const otp = digit1 + digit2 + digit3 + digit4 ;
//         console.log("otp =>" , otp);
//         setOtp(otp);
//         // if (value.length <= 4) {
//         //     setOtp(value);
//         // }
//     };

//     const handleOtpSubmit = () => {
//         console.log("submit otp :" , otp);
//     }




//   return (
//     <div>
//         <div>
//             <div className='login-body'>
//                 <div class="container login-screen mt-3">
//                     <div class="logo-login mt-5">
//                         <center><img src="images/logo-icon.webp" style={{height:'80px'}}/></center>
//                         <h4 class="theme-color text-center mt-5">Enter One Time Password (OTP)</h4>
//                         <p class="text-light text-center"><i>A four digit  OTP has been sent to your mobile number</i></p>
//                     </div>
//                     <div class="container_otp">
//                         <div id="inputs" class="container_otp">
//                             {/* <input className='input-otp'
//                                 type="text"
//                                 name="otp"
//                                 value={otp}
//                                 onChange={handleOtpChange}
//                                 placeholder="Enter OTP"
//                                 required
//                                 autoComplete='off'
//                             /> */}
//                              <input class="input-otp" type="text" maxlength="1" name="d1"  onChange={handleOtpChange} autoComplete='off'/>
//                              <input class="input-otp" type="text" maxlength="1" name="d2"  onChange={handleOtpChange} autoComplete='off'/>
//                              <input class="input-otp" type="text" maxlength="1" name="d3"  onChange={handleOtpChange} autoComplete='off'/>
//                              <input class="input-otp" type="text" maxlength="1" name="d4"  onChange={handleOtpChange} autoComplete='off'/>
//                         </div>
//                     </div>
//                     <div class="login-continue-btn mt-5">
//                         <button onClick={handleOtpSubmit} class="btn btn-light">VERIFY OTP</button>
//                     </div>
//                     <div class="login-privacy text-center text-white mt-4">
//                         <p> <span style={{color:'#d2ab67'}}>RESEND OTP?</span></p>
//                     </div>
                   
//                 </div>
//             </div>
//         </div>
// </div>
//   )
// }

// export default Otp
import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2'
//import './Otp.css'; // Make sure to create and use a corresponding CSS file for styles

const Otp = () => {
   
  const [digits, setDigits] = useState({ d1: '', d2: '', d3: '', d4: '' });
  const inputRefs = useRef([]);
  const [notification, setNotification] = useState('');

  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 1) return;

    setDigits((prev) => ({ ...prev, [name]: value }));

    // Move focus to the next input if value is entered
    if (value && inputRefs.current[parseInt(name.charAt(1))]) {
      inputRefs.current[parseInt(name.charAt(1))].focus();
    }

    // Update the OTP state
    const otp = Object.values({ ...digits, [name]: value }).join('');
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    const index = parseInt(name.charAt(1));

    if (e.key === 'Backspace' && !value && index > 1) {
      inputRefs.current[index - 2].focus();
    }
  };

  const showSuccessAlert = () => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your order placed successfully",
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'swal2-popup', // Custom class for the popup
        title: 'swal2-title', // Custom class for the title
      }
    });
  };

  const handleOtpSubmit = () => {
    const otp = Object.values(digits).join('');
    if (otp === '1234') { // Replace with actual OTP verification logic
        setNotification('Verification successful. Please confirm your order.');
       // showSuccessAlert();
      } else {
        setNotification('Invalid OTP. Please try again.');
      }

  };

  return (
    <div>
      <div className='login-body'>
        <div className='container login-screen mt-3'>
          <div className='logo-login mt-5'>
            <center><img src='images/logo-icon.webp' alt='Logo' style={{ height: '80px' }} /></center>
            <h4 className='theme-color text-center mt-5'>Enter One Time Password (OTP)</h4>
            <p className='text-light text-center'><i>A four digit OTP has been sent to your mobile number</i></p>
          </div>
          <div className='container_otp'>
            <div id='inputs' className='container_otp'>
              <input
                className='input-otp'
                type='text'
                maxLength='1'
                name='d1'
                ref={(el) => (inputRefs.current[0] = el)}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <input
                className='input-otp'
                type='text'
                maxLength='1'
                name='d2'
                ref={(el) => (inputRefs.current[1] = el)}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <input
                className='input-otp'
                type='text'
                maxLength='1'
                name='d3'
                ref={(el) => (inputRefs.current[2] = el)}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <input
                className='input-otp'
                type='text'
                maxLength='1'
                name='d4'
                ref={(el) => (inputRefs.current[3] = el)}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='login-continue-btn mt-5'>
            <button onClick={handleOtpSubmit} className='btn btn-light'>VERIFY OTP</button>
          </div>
          <div className='login-privacy text-center text-white mt-4'>
            <p><span style={{ color: '#d2ab67' }}>RESEND OTP?</span></p>
          </div>
          {notification && (
            <div class="alert alert-success">
            <strong>Success!</strong> Indicates a successful or positive action.
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Otp;
