import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './faq.css';

const Faq = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


return (
    <>
    <Navbar />
    <div class="header-details" style={{height:'100px', background:'black'}}>

</div>
<div class="eight-section-faq mt-5">
<div class="container">
  <div class="faq-heading text-center mt-5 mb-5">
    <h3 class="theme-color">FREQUENTLY ASKED</h3>
    <h3>QUESTIONS</h3>
  </div>
  <div class="faq-accordion">
    <h3>The Farm</h3>
    <div class="accordion" id="accordionExample">
      <div class="">
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed " type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="false" aria-controls="collapseOne">
                   Where is Whyte Farms located?
                </button>
              </h2>
              <div id="collapseOne"
                class="accordion-collapse collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whyte Farms is located near a small village known as Shahbad (Tehsil Tijara), in Alwar District, Rajasthan.

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  Can we visit the farm?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yes, of course. We would be more than delighted to give you a tour of the farm. Not just that, we will shower you with a lot of goodies for your interest. A prior appointment is must though. In our town we don't let our guests go without a celebration.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  What facilities are available on the farm?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whyte Farms is spread over 25 acres and built to international standards. It's a world-class dairy farm that uses state-of-the-art-facility and processes which are essential to keep the cows comfortable and happy, such as climate controllers, massage brushes, cleanliness tools and high quality feed and water.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFour"
                  aria-expanded="false" aria-controls="collapseOne">
                 How is Whyte Farms different from other farms?
                </button>
              </h2>
              <div id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                Whyte Farms is one of the most technologically advanced dairies in India. Whyte Farms in an integrated dairy farm where its has its own agricultural lands for fodder, state of the art barns, fully automated milking parlour, chilling units, pasteurization unit, packaging line, cold stores as well as our own cold-chain delivery vans and bikes for home delivery.

In other words, we have all that it takes to grow goodness. We are a self sufficient, sustainable, high tech organic farm.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFive"
                  aria-expanded="false" aria-controls="collapseTwo">
                 What other activities are carried at the farm?
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                 We do Organic Vegetable Farming, processing of Vermicompost (Kechua Manure), Eco Tourism, and Carbon Sink (15000 trees planted in the last on year)
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false" aria-controls="collapseEight">
                  Why is our milk the best Milk in the market?
                </button>
              </h2>
              <div id="collapseEight" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whyte Farms is not an aggregator of milk but we produce our own milk. As producers of milk, we have control over every minute process involved in producing good wholesome milk. We take special care of each detail, beginning from the healthiest breed of cows, the right mix of organic fodder, regular supervision of cow's health and comfort, milking hygiene, maintaining highest standards of sheds and barns, and finally ensuring safe delivery of the cow's wholesome milk straight from our farm to your home.

We don't put any preservatives and adulterants
We don't put any chemicals in the fodder for cows, they are only fed farm grown organic fodder.
We don't give antibiotics or hormonal steroids to our cows.
The milking and packaging process is fully mechanised, hence completely hygienic.
We keep our cows happy. They are free to roam, bask in the sun or rest in their comfy sheds.
Better still, just try a glass of our Milk and you will know why its better than any other milk and why it's so delicious!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faq-accordion">
    <h3>The Milk</h3>
    <div class="accordion" id="accordionExample">
      <div class="">
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSeven">
                <button class="accordion-button collapsed " type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseSeven"
                  aria-expanded="false" aria-controls="collapseSeven">
                  Why is our milk the best Milk in the market?
                </button>
              </h2>
              <div id="collapseSeven"
                class="accordion-collapse collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whyte Farms is not an aggregator of milk but we produce our own milk. As producers of milk, we have control over every minute process involved in producing good wholesome milk. We take special care of each detail, beginning from the healthiest breed of cows, the right mix of organic fodder, regular supervision of cow's health and comfort, milking hygiene, maintaining highest standards of sheds and barns, and finally ensuring safe delivery of the cow's wholesome milk straight from our farm to your home.

                  We don't put any preservatives and adulterants
                  We don't put any chemicals in the fodder for cows, they are only fed farm grown organic fodder.
                  We don't give antibiotics or hormonal steroids to our cows.
                  The milking and packaging process is fully mechanised, hence completely hygienic.
                  We keep our cows happy. They are free to roam, bask in the sun or rest in their comfy sheds.
                  Better still, just try a glass of our Milk and you will know why its better than any other milk and why it's so delicious!
                  
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingEight">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapsesecondEight"
                  aria-expanded="false" aria-controls="collapseEight">
                  Do you supply toned milk as well?
                </button>
              </h2>
              <div id="collapsesecondEight" class="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Currently we only have Whole Cow's Milk. The fat content of this milk is between 3.5 to 4%. However, whole cow's milk is lighter than normal toned milk available in the market, which is primarily Buffalo's milk. Almost 40% of the fat in the milk can be extracted in the form of cream-layer/Malai when you boil the milk. We would soon be coming with reduced fat milk variants. </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFourteen">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false" aria-controls="collapseFourteen">
                  What do you mean by whole milk?
                </button>
              </h2>
              <div id="collapseFourteen" class="accordion-collapse collapse"
                aria-labelledby="headingFourteen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whole milk is milk from which no constituents have been removed or adjusted. It is a non-standardised milk where the fat has not been set to a particular value through extra processing . Whole milk is pure milk containing all its goodness in its natural form as received from the cow. It's milk, the way nature has made it. Hence, Whyte Farms Milk is Whole Cow's Milk.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTen">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTen"
                  aria-expanded="false" aria-controls="collapseTen">
                  Will i get Thick malai on it after it is boiled and cooled?
                </button>
              </h2>
              <div id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Full fat milk from cow cannot be compared with conventional brands milk, because the latter is mainly Buffalo's milk. Also, in conventional brands, cream/fat is added as per percentage proportions to make different variants. Conventional full cream brands contain 6% fat.
                  Cow's whole milk cannot be more than 4% fat because it is the natural lactating property of the cow that its light in terms of fat, thus easy to digest. That’s why its widely known that cow milk fat is GOOD FAT.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseEleven"
                  aria-expanded="false" aria-controls="collapseTwo">
                  Can we set Curd with Whyte Milk? is there any particular method for that?
                </button>
              </h2>
              <div id="collapseEleven" class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Firstly, the milk available in the market has certain percentage of Skimmed Milk Powder (SMP) mixed, hence you get curd very thick and creamy (chakka kind of), because of the powder content in the milk.
                  Whyte Farms Milk is 100% farm fresh, direct from the udder, so there is natural Solids Not Fat (SNF) which is relatively low in comparison to conventional milk brands
                  Setting curd with Whyte Farms Milk is altogether a different vis-à-vis the conventional brands. Completely boil the milk ﬁrst and let it cool down a bit to the temperature when you put/mix culture. Cover the vessel with a cloth or set it in an insulated vessel to maintain the temperature. The setting of curd will take a tad bit longer as compared to usual process. Once set, we recommend you refrigerate it, to set perfectly.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false" aria-controls="collapseTwelve">
                  What is pasteurisation?
                </button>
              </h2>
              <div id="collapseTwelve" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  It is a process that heats the milk at a particular temperature for a stipulated time to ensure killing of harmful bacteria while maintaining its nutrient value. Thus, making the milk safe to drink straight from the bottle.                      </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="false" aria-controls="collapseThirteen">
                  Is the milk pasteurised?
                </button>
              </h2>
              <div id="collapseThirteen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yes, it is. It reduces or kills viable pathogens that are likely to cause diseases. Pasteurised milk is preferred all over the world with scientifically documented health benefits. The process of pasteurisation has been designed for the safety of the consumer.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="false" aria-controls="collapseFifteen">
                  Do I need to boil the milk?
                </button>
              </h2>
              <div id="collapseFifteen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  No, since our milk is already pasteurised, it can be consumed without boiling. Drink it chilled for best taste.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSixteen"
                  aria-expanded="false" aria-controls="collapseSix">
                  I've had cow's milk when I was a child, and it was always yellow in colour. But the milk you supply is white. Is it really cow's milk?
                </button>
              </h2>
              <div id="collapseSixteen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yellow color in milk is mainly due to presence of carotenoid. Some breeds have high amounts of it while others have low amount of carotenoid generation. Hence, the yellow tinge in the milk can vary by breed, the food and other factors.
                  Presence of less yellow tinge is nothing to worry about. It is due to the fresh seasonal diet fed to the cows.
                  After boiling and refrigerating the milk, you can witness that the malai/cream layer on the top is slightly yellow.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeventeen"
                  aria-expanded="false" aria-controls="collapseSix">
                  What is A2 milk?
                </button>
              </h2>
              <div id="collapseSeventeen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Cow's milk contains two kinds of proteins - A1 and A2. It is a myth currently that A1 or A2 protein is dependent on the breed of the cow but that's not true as all breeds contain A1 and A2. After the genetic test of each cow, the percentage of A2 is ascertained. At our farm, milk is classified as A2 only after the cows undergo genetic tests.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEighteen"
                  aria-expanded="false" aria-controls="collapseSix">
                  Is Whyte Farms certiﬁed?
                </button>
              </h2>
              <div id="collapseEighteen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yes, we are certified by International organization for standardization(ISO). And, also licensed by Food Safety and Standards Authority of India(FSSAI).</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNineteen"
                  aria-expanded="false" aria-controls="collapseSix">
                  How can you be assured of the milk quality?
                </button>
              </h2>
              <div id="collapseNineteen" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  We provide sample test reports from FSSAI accredited labs on a periodic basis to give you assurance on the milk quality. All the subscribers are welcome to visit the farm with prior appointment, to experience the farm and the processes.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwenty"
                  aria-expanded="false" aria-controls="collapseSix">
                  Why is the milk thickness inconsistent unlike the branded packaged milk?
                </button>
              </h2>
              <div id="collapseTwenty" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Whyte Farms milk is farm fresh. We do not do any sort of processing on the milk. Branded packaged milk is standardized and homogenized to ensure consistency in the milk, but these processes alter the natural state of the milk. Our milk thickness and taste would be impacted by weather, local seasonal fodder fed to the cows and lactation cycle of our cows.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwnterone"
                  aria-expanded="false" aria-controls="collapseSix">
                  What is the shelf life of the milk?
                </button>
              </h2>
              <div id="collapseTwnterone" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The milk , when stored under refrigerated conditions will last for three days.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faq-accordion">
    <h3>The Cows</h3>
    <div class="accordion" id="accordionExample">
      <div class="">
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed " type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwentytwo"
                  aria-expanded="false" aria-controls="collapseOne">
                  Which breed of cows do you have?
                </button>
              </h2>
              <div id="collapseTwentytwo"
                class="accordion-collapse collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Presently we are breeding Dutch Holstein-Friesian and Gir cows/breed.
                   </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwentythree"
                  aria-expanded="false" aria-controls="collapseTwo">
                  Do you give hormone injections to the Cows?
                </button>
              </h2>
              <div id="collapseTwentythree" class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  We are strictly against the use of Oxytocin or any other form of hormones.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyfour"
                  aria-expanded="false" aria-controls="collapseFourteen">
                  How is this milk Antibiotic free?
                </button>
              </h2>
              <div id="collapseTwentyfour" class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The milk from any cow which is on an antibiotic course is excluded. Therefore, the milk is completely antibiotic free.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwenteyfive"
                  aria-expanded="false" aria-controls="collapseOne">
                  What kind of fodder do these cows eat?
                </button>
              </h2>
              <div id="collapseTwenteyfive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Our cows get a planned meal every day. We specially grow high quality Maize, Alfalfa, Greens, Soya and Bran to prepare a wholesome diet to keep them healthy and happy. Our nutritionists ensure that there is the right balance of minerals and vitamins. They create a menu for different ages of the cows and their physical needs. The menu, as you would have guessed, is changed regularly to include fresh seasonal crops and specials to keep our cows excited and munching all day.

We call this a Total Meal Ration and it is designed to keep every cow in prime healthy condition. The result is rich wholesome milk, that is natural and full of the vital goodness of cow's milk.
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faq-accordion">
    <h3>Milk Processing, Packaging and Logistics</h3>
    <div class="accordion" id="accordionExample">
      <div class="">
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed " type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThirty"
                  aria-expanded="false" aria-controls="collapseOne">
                  Where is the processing Plant?
                </button>
              </h2>
              <div id="collapseThirty"
                class="accordion-collapse collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  At our farm itself.   </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThirtytwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  What do you do in the processing plant?
                </button>
              </h2>
              <div id="collapseThirtytwo" class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  After milking, the milk is immediate chilled to 4 degrees followed by pasteurisation and packaging.

                  The packaged milk is then dispatched for home deliveries thereafter.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo1">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThirtytwo1"
                  aria-expanded="false" aria-controls="collapseTwo">
                   What is pasteurisation?
                </button>
              </h2>
              <div id="collapseThirtytwo1" class="accordion-collapse collapse"
                aria-labelledby="headingTwo1"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                It is a process that heats the milk at a particular temperature for a stipulated time to ensure killing of harmful bacteria while maintaining its nutrient value. Thus making the milk safe to drink straight from the bottle.
                </div>
              </div>
            </div>
           
          </div>
        </div>
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThirtyfour"
                  aria-expanded="false" aria-controls="collapseOne">
                  What kind of bottle do you use?
                </button>
              </h2>
              <div id="collapseThirtyfour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  As per our Green initiative, Whyte Farms has transitioned to Glass bottles.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFive"
                  aria-expanded="false" aria-controls="collapseThirtyfive">
                  Do I have to return the empty bottles?
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  As per our green initiative, we re-use the glass bottles, Hence we require you to rinse and return the empty glass bottle on your next scheduled delivery. Loss of any bottles will be charged at Rs. 50 each.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive1">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFive1"
                  aria-expanded="false" aria-controls="collapseThirtyfive">
                  How is the milk packaged?
                </button>
              </h2>
              <div id="collapseFive1" class="accordion-collapse collapse"
                aria-labelledby="headingFive1"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                Milking is done by fully automatic milking machines. Post-milking, the milk is immediately chilled to 4 degree Celsius using PHE, then pasteurised, packed in tamper proof bottles and transported to our local cold store chains – from there it is delivered to each subscriber by our team.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive11">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFive11"
                  aria-expanded="false" aria-controls="collapseThirtyfive">
                  How will you maintain optimal temperature during transportation?How is the milk packaged?
                </button>
              </h2>
              <div id="collapseFive11" class="accordion-collapse collapse"
                aria-labelledby="headingFive11"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                Whyte Farms milk is transported from plant to depot in refrigerated vans. Each and every depot has refrigeration facilities. The milk is then delivered to your doorstep in insulated boxes to maintain the optimum temperature.
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faq-accordion">
    <h3>Subscriptions, Payments and Deliveries</h3>
    <div class="accordion" id="accordionExample">
      <div class="">
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed " type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFourtyone"
                  aria-expanded="false" aria-controls="collapseOne">
                  How does the home delivery of milk subscription work?
                </button>
              </h2>
              <div id="collapseFourtyone"
                class="accordion-collapse collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  We offer a Pre-paid milk subscription. The subscription can be made from the website or through our app “Whyte Farms” available on both android and iOS respectively. All you need to do is to register yourself and make your subscription as per your preference and requirement. You can follow these simple steps to make you subscription.
                  Log into your account via website or whyte farms app. Register yourself and fill in the details.
                  Once you have logged in recharge you wallet as per your comfort level.
                  Choose your subscription from the various modes available like every day, alternate day, every third or fourth day respectively. You can select the quantity as per your requirement.
                  If your requirement is not catered by the above mentioned options, then we also offer a customized solution wherein you can choose the day and the quantity required by you on those particular days. All this can be managed by just the click of the button.
                  We are also available in select stores such as Le Marche, Modern Bazar, Food hall, Needs and milkbasket.
                  In case of any assistance you can call us on our toll free no 18001236455.  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFourtytwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  What are the modes of payment?
                </button>
              </h2>
              <div id="collapseFourtytwo" class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  You can pay through:

                  Online mode (Credit card, Debit card, Net banking, other wallet options)
                  Cash / Cheque ( In case of cash collection please intimate us about it via call or mail ) </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourtythree"
                  aria-expanded="false" aria-controls="collapseFourteen">
                  What are the milk delivery timings and procedure?
                </button>
              </h2>
              <div id="collapseFourtythree" class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The milk is delivered by our own team of delivery boys between 6:00 am - 9:30 am every morning. You can avail the following delivery preferences - delivery in hand, leave at the door step, ring the bell and leave. The same can be updated from the app or website itself.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFourtyfour"
                  aria-expanded="false" aria-controls="collapseOne">
                  Can I pause or temporarily opt out of the milk delivery?
                </button>
              </h2>
              <div id="collapseFourtyfour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yes, the milk delivery can be stopped and resumed at any time before 11.00 pm a day prior from our website or app. You can also call our customer support a day in advance between 9am - 7pm.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseFourtyfive"
                  aria-expanded="false" aria-controls="collapseTwo">
                  Can I add/change my milk requirement if I’m travelling or have guests over?
                </button>
              </h2>
              <div id="collapseFourtyfive" class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Yes, its possible to make changes a day prior by 11:30 pm through our app or website. In case the same needs to be communicated via call or mail it can only be updated between 9am - 7pm, a day prior.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false" aria-controls="collapseFourtysix">
                  How would temporarily changing the milk quantity affect the duration of my subscription?
                </button>
              </h2>
              <div id="collapseFourtysix" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The delivered quantity will be adjusted against the subscribed quantity, on a daily basis. A monthly delivery statement will be sent by email indicating the subscription credit in the milk quantity. The delivery will continue uninterrupted within the subscription schedule. </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourtyseven"
                  aria-expanded="false" aria-controls="collapseFourtyseven">
                  What is the delivery schedule on holidays?
                </button>
              </h2>
              <div id="collapseFourtyseven" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Your milk is delivered 7 days a week, 365 days a year, unless there is an exceptional emergency situation.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourtyeight"
                  aria-expanded="false" aria-controls="collapseFifteen">
                  Are there any additional charges beyond the subscription rate?
                </button>
              </h2>
              <div id="collapseFourtyeight" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  There is no additional charge beyond the subscription amount.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourtynine"
                  aria-expanded="false" aria-controls="collapseSix">
                  What should be the minimum order for subscription?
                </button>
              </h2>
              <div id="collapseFourtynine" class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The minimum order is one litre only.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<Footer/>
        </>
     );
    }
    
    export default Faq;
