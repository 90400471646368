// FacebookPixel.js

import React, { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';

const Facebook = ({ title }) => {

  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('282551210445191', advancedMatching, options);

  useEffect(() => {
    const utm_campaign = localStorage.getItem('utm_campaign');
    const utm_medium = localStorage.getItem('utm_medium');
    ReactPixel.trackSingle('282551210445191', title, {
      utm_campaign: utm_campaign ? JSON.parse(utm_campaign).value : '',
      utm_medium: utm_medium ? JSON.parse(utm_medium).value : '',
      utm_source: localStorage.getItem('utm_source') ? JSON.parse(localStorage.getItem('utm_source')).value : ''
    });
  }, []);

  return null;
};

export default Facebook;
