import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const Account = () => {
    const { user } = useContext(AuthContext);

    return (
        <div>
            <h1>Account Details</h1>
            {user ? (
                <div>
                    <p>Email: {user.email}</p>
                    {/* Add more user details here */}
                </div>
            ) : (
                <p>Please login to view your account details.</p>
            )}
        </div>
    );
};

export default Account;
