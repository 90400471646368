import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const loggedInUser = Cookies.get('user');
        
        if (loggedInUser) {
            try {
                const parsedUser = JSON.parse(loggedInUser);
                setUser(parsedUser);
                
            } catch (error) {
                console.error('Error parsing user from cookie:', error); // Debugging log
            }
        }
        setLoading(false); // Set loading to false after checking cookies
    }, []);

    const login = (phone) => {
        const userData = { phone }; // Example user data
        setUser(userData);
        Cookies.set('user', JSON.stringify(userData), { expires: 7 });
        
    };

    const logout = () => {
        setUser(null);
        Cookies.remove('user');
        // Ensure the cookie has been removed
        const loggedInUser = Cookies.get('user');
        
    };



    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;


