import React from 'react';
import { Link } from 'react-router-dom';

const FailurePage = () => {
  return (
    <div style={{ marginTop: '10rem' }}>
      <div className="text-center">
        <h2>Your payment is failed</h2>
        <p>Please try again</p>
        <br />
        <Link to="/" className="subscribe-button">
          <span style={{ color: 'black', fontWeight: 'bold' }}>Go To Dashbaord</span>
        </Link>
      </div>
    </div>
  );
};

export default FailurePage;



// import React from 'react';
// import { useLocation } from 'react-router-dom';

// const FailurePage = () => {
//     const location = useLocation();
//     const query = new URLSearchParams(location.search);
//     const data = JSON.parse(query.get('data'));

//     return (
//         <div>
//             <h2>Payment Failed</h2>
//             <p>Unfortunately, your payment could not be processed. Please try again.</p>
//             <pre>{JSON.stringify(data, null, 2)}</pre>
//         </div>
//     );
// };

// export default FailurePage;
