import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from "./Footer";
import './Ourfarm.css';


const Ourfarm = () => {
  const navigate = useNavigate();
  const buyNow = () => {
    navigate(`/product_details/HNgdBpryadraSsfYv1zh`);
  };
  
  return (
    <>
     <div class="our-story-section">
    <div class="white-cow-img">
    <img src="images/white-cow.png"/>
  </div>
  <div>
    <h1 class="heading-story">Our Farm</h1></div>
    <div class="story-overlay">
    <Navbar/>
    </div>
   
   </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-7">
        <p class="ourfarm-content"><strong class="text-middle">THE FARM</strong></p>
        <p class="ourfarm-content"><img src="images/process/divider.png" alt="" class="divder-img" /></p>
        <p class="ourfarm-content">Spread over 25 acres of land on the border of Rajasthan, the beautiful piece of land is able to keep more than 500 cows. A haven for cattle, it is designed to keep them mooing and chewing even when they are going through mood swings. Littered with comfortable places to chill, it has got luxurious barns with giant fans and high roofs to keep them cool during the summer, even the sand on the ground is imported to give them the best experience. These little things matter to the cows. It’s a lot of hard work but worth it at the end. After all it’s the happy cows that give the best quality milk.
        </p>
      </div>
      <div class="col-lg-5 farm-img-div">
        <img src="images/process/farm-round-img.png" alt="" class="img-fluid farm-img" />
      </div>
    </div>

    <div class="d-flex justify-content-center line-hide">
      <img src="images/process/connect-line1.png" alt="" class="img-fluid farm-img" />
    </div>

    <div class="row">
      <div class="col-lg-5 farm-img-div order-lg-1 order-2">
        <img src="images/process/feed-round-img.png" alt="" class="img-fluid farm-img" />
      </div>
      <div class="col-lg-7 order-lg-2 order-1">
        <p class="ourfarm-content"><strong class="text-middle">THE FEED & THE FODDER</strong></p>
        <p class="ourfarm-content"><img src="images/process/divider.png" alt="" class="divder-img" /></p>
        <span><b>What's cooking?</b></span>
        <p class="ourfarm-content">They are fed with organically grown greens and fodder made especially for them at the farm itself. We make sure that their food is free from any kind of insecticide, pesticide and other Chemicals.</p>
        <span><b>TMR Feeding Machines from Germany</b></span>
        <p class="ourfarm-content">It’s like having a personal nutritionist for the cows. TMR, Total Meal Ration is a method of feeding cows that combines all forages, grains, protein feeds, minerals, vitamins and additives formulated to a specified nutrient concentration into a single feed mix. Our machine makes sure that the cows get the most nutritious diet for healthy rumination process and keeps the cows fit and active in general.</p>
      </div>
    </div>

    <div class="d-flex justify-content-center line-hide">
      <img src="images/process/connect-line2.png" alt="" class="img-fluid farm-img" />
    </div>

    <div class="row">
      <div class="col-lg-7">
        <p class="ourfarm-content"><strong class="text-middle">THE MILKING PARLOUR</strong></p>
        <p class="ourfarm-content"><img src="images/process/divider.png" alt="" class="divder-img" /></p>
        <p class="ourfarm-content">Our farm is equipped with the most advanced milking machine, the 2X6 Herringbone parlour. It senses the position of the cow, records the amount of milk delivered, and other information which it sends to the main computer. On the ground, it can milk 12 cows in 7 minutes. The cows walk into the parlour and are milked automatically by the machines. See no hands involved.
        </p>
        <p class="ourfarm-content">The fresh milk then passes through the PHE pipelines and is chilled to 4º c immediately. This process preserves the milk and keeps the nutrients of the milk intact.</p>
      </div>
      <div class="col-lg-5 farm-img-div">
        <img src="images/process/parlor-round-img.png" alt="" class="img-fluid farm-img" />
      </div>
    </div>

    <div class="d-flex justify-content-center line-hide">
      <img src="images/process/connect-line1.png" alt="" class="img-fluid farm-img" />
    </div>

    <div class="row">
      <div class="col-lg-5 order-lg-1 order-2 farm-img-div">
        <img src="images/process/milk-round-img.png" alt="" class="img-fluid farm-img" />
      </div>
      <div class="col-lg-7 order-lg-2 order-1">
        <p class="ourfarm-content"><strong class="text-middle">TREATING THE MILK RIGHT</strong></p>
        <p class="ourfarm-content"><img src="images/process/divider.png" alt="" class="divder-img" /></p>
        <p class="ourfarm-content">We've got PHE Most farms in India don't have this technology. While other cooling technology takes hours to cool the milk, the PHE does the job in seconds. The process destroys bacteria immediately and is essential in maintaining milk quality & taste.
        </p>
        <p class="ourfarm-content">Pasteurisation
          The chilled milk is then pasteurised which makes it safe to drink without the need to boil it. The milk is packed in barrier proof bottles and is ready to be sent to your doorstep</p>
      </div>
    </div>

    <div class="d-flex justify-content-center line-hide">
      <img src="images/process/connect-line2.png" alt="" class="img-fluid farm-img" />
    </div>

    <div class="row">
      <div class="col-lg-7">
        <p class="ourfarm-content"><strong class="text-middle">DELIVERING FRESHNESS</strong></p>
        <p class="ourfarm-content"><img src="images/process/divider.png" alt="" class="divder-img" /></p>
        <p class="ourfarm-content">The fresh milk is delivered to your homes within hours of milking. Our cold-chain delivery process ensures that the milk is good to the last drop after it reaches you. Sweet isn't it!</p>
        <p class="ourfarm-content">So all you have to do is to sit back and enjoy your bottle of goodness.</p>
      </div>
      <div class="col-lg-5 farm-img-div">
        <img src="images/process/truck-img.png" alt="" class="img-fluid farm-img" />
      </div>
    </div>

    <div class="row com-milk">
      <div class="col-5 d-flex justify-content-end">
        <img src="images/5.png" style={{ width: "170px" }} alt="" class="img-fluid cow-img" />
      </div>
      <div class="col-5 cow-milk">
        <img src="images/process/right-top-line.png" alt="" class="img-fluid farm-img" />
      </div>
    </div>
    <div class="row mb-5 mt-5">
      <div class="col-12">
        <h2 class="text-center">Whyte Farms Cow Milk</h2>
        {/* <p class="text-center"><img src="images/divider.png" alt="" class="divder-img" /></p> */}
        <p class="text-center mb-3">No need to boil it. Oh yes, you can drink it directly. Pure and so delicious,<br /> our milk comes from one of the finest farms in India.</p>
        <center><button type="button" class="btn btn-dark ourfarm-order-btn mt-3 mb-5" onClick={buyNow}>Order Now</button></center>
      </div>
    </div>
  </div>

<Footer/>

    </>
  )
}
export default Ourfarm

