import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import emailjs from 'emailjs-com';
import Moment from "moment";
import { extendMoment } from "moment-range";

function BookYourFarm() {
    const moment = extendMoment(Moment);
    const tomorrow = moment().add(1, 'day').toDate();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobile: '',
        dateOfVisit: '',
        timeSlot: '',
        message: '',
        captchaInput: '',
    });

    const [generatedCaptcha, setGeneratedCaptcha] = useState('');

    const generateCaptcha = () => {
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captchaLength = 6;
        let captcha = '';
        for (let i = 0; i < captchaLength; i++) {
            let randomIndex = Math.floor(Math.random() * characters.length);
            captcha += characters[randomIndex];
        }
        setGeneratedCaptcha(captcha);
    };
    useEffect(() => {
        generateCaptcha();
    }, []);
    const validateForm = () => {
        const errors = {};
        debugger;
        
        if (!formData.fullName.trim()) {
            errors.name = 'Name is required';
        }

        
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email format';
            alert(errors.email);
            return;
        }

        
        if (!formData.mobile.trim()) {
            errors.mobile = 'Mobile number is required';
        } else if (!isValidMobile(formData.mobile)) {
            errors.mobile = 'Invalid mobile number';
            alert(errors.mobile);
            return;
        }

        
        if (!formData.message.trim()) {
            errors.message = 'Message is required';
        }

        
        if (!formData.captchaInput.trim()) {
            errors.captchaInput = 'Captcha is required';
        } else if (formData.captchaInput.trim() !== generatedCaptcha.trim()) {
            errors.captchaInput = 'Incorrect Captcha code';
            alert(errors.captchaInput);
            return;
        }

        return errors;
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidMobile = (mobile) => {
        return /^[0-9]{10}$/.test(mobile);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.captchaInput !== generatedCaptcha) {
            alert('Invalid CAPTCHA, please try again.');
            generateCaptcha();
            return;
        }


        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
        emailjs.send('service_9gkr6hu', 'template_c027rak', {
            fullName: formData.fullName,
            email: formData.email,
            mobile: formData.mobile,
            dateOfVisit: formData.dateOfVisit,
            timeSlot: formData.timeSlot,
            message: formData.message,
        }, 'vS1fGTreJ2Hxj2m1V')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Email sent successfully!');
            // Optionally, clear the form here
            setFormData({
                fullName: '',
                email: '',
                mobile: '',
                dateOfVisit: '',
                timeSlot: '',
                message: '',
                captchaInput: ''
            });
            generateCaptcha(); // Regenerate CAPTCHA
        }, (err) => {
            console.error('FAILED...', err);
            alert('Failed to send email.');
        });
        }else {
            setErrors(errors);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <>

<div class="our-story-section">
    <div class="white-cow-img">
    <img src="images/white-cow.png" alt="White Cow" />
  </div>
  <div>
    <h1 class="heading-story">BOOK YOUR FARM VISIT</h1></div>
    <div class="story-overlay">
    <Navbar/>
    </div>
   
   </div>
            {/* <div className="header-banner-bookfarm">
                <div className="container banner-heading-bookfarm">
                    <div className="white-cow-img" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="images/white-cow.png" alt="White Cow" />
                    </div>
                    <h1 className="text-center">BOOK YOUR FARM VISIT</h1>
                </div>
            </div> */}
            <div className="container form-container-bookfarm">
                <div className="row mt-5 form-content form-container-row-bookfarm">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form onSubmit={handleSubmit} id="contactForm">
                            <div className="mb-3">
                                <input
                                    type="text"
                                    name="fullName"
                                    placeholder="Your Full Name *"
                                    className="form-control br-bookfarm"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email ID *"
                                    className="form-control br-bookfarm"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="number"
                                    name="mobile"
                                    placeholder="Mobile Number *"
                                    className="form-control br-bookfarm"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label>Date of Visit</label>
                                <input
                                    type="date"
                                    name="dateOfVisit"
                                    className="form-control br-bookfarm"
                                    value={formData.dateOfVisit}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label>Choose Time Slot</label>
                                <select
                                    name="timeSlot"
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={formData.timeSlot}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Time Slot</option>
                                    <option value="9:00 AM - 11:00 AM">9:00 AM - 11:00 AM</option>
                                    <option value="11:00 AM - 1:00 PM">11:00 AM - 1:00 PM</option>
                                    <option value="2:00 PM - 4:00 PM">2:00 PM - 4:00 PM</option>
                                    <option value="4:00 PM - 6:00 PM">4:00 PM - 6:00 PM</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <textarea
                                    name="message"
                                    className="form-control br-bookfarm"
                                    rows="3"
                                    placeholder="Write Message/Any Query ? *"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    name="captchaInput"
                                    placeholder="Enter Captcha Code *"
                                    className="form-control br-bookfarm"
                                    value={formData.captchaInput}
                                    onChange={handleChange}
                                    required
                                />
                                <small id="captchaHelp" className="form-text text-muted">
                                    Please enter the CAPTCHA code above.
                                </small>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    id="generatedCaptcha"
                                    className="form-control br-bookfarm"
                                    value={generatedCaptcha}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <button
                                    type="button"
                                    onClick={generateCaptcha}
                                    className="captcha-btn br-bookfarm"
                                >
                                    Generate CAPTCHA
                                </button>
                            </div>
                            <input type="submit" className="form-control br bg-colss-bookfarm" value="Send" />
                        </form>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>Farm Address</h3>
                            <p><strong>Whyte Farms LLP</strong><br/>Village Shahbad, Tehsil Tijara,<br/>Distt. Alwar, Rajasthan - 301411, <br/>India.<br/><br/>Timing - 9:00 AM to 7:00 PM</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BookYourFarm;
