import React,{useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

const UtmSource = () => {
  const [searchParams] = useSearchParams();
  const storeUTMParams = () => {
    const utm_source = searchParams.get('utm_source');
    const utm_medium = searchParams.get('utm_medium');
    const utm_campaign = searchParams.get('utm_campaign');
  
    const timestamp = new Date().getTime();
    localStorage.setItem('utm_source', JSON.stringify({ value: utm_source, timestamp }));
    localStorage.setItem('utm_medium', JSON.stringify({ value: utm_medium, timestamp }));
    localStorage.setItem('utm_campaign', JSON.stringify({ value: utm_campaign, timestamp }));
  };

  const checkAndDeleteUTMParams = () => {
    const currentTime = new Date().getTime();
    const oneday = 24*60 * 60 * 1000;

    ['utm_source', 'utm_medium', 'utm_campaign'].forEach(param => {
      const storedParam = localStorage.getItem(param);
      if (storedParam) {
        const { value, timestamp } = JSON.parse(storedParam);
        if (currentTime - timestamp > oneday) {
          localStorage.removeItem(param);
        }
      }
    });
  };

  useEffect(()=>{
    storeUTMParams();
    checkAndDeleteUTMParams();
  },[])
  return (
    <>

    </>
  )
}

export default UtmSource