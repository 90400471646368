import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import db from "../Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const SubscriptionDetails = () => {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [productImages, setProductImages] = useState({});
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const moment = extendMoment(Moment);

    useEffect(() => {
        const unsubscribeProducts = db.collection('products_data').onSnapshot(snapshot => {
            const images = {};
            snapshot.forEach(doc => {
                const data = doc.data();
                images[data.productName] = data.image;
            });
            setProductImages(images);
        }, error => {
            console.error('Error fetching product images:', error);
        });

        const unsubscribeSubscriptions = db.collection('subscription_data').onSnapshot(snapshot => {
            const subsData = snapshot.docs.map(doc => ({
                id: doc.id,
                subs: doc.data()
            }));
            setSubscriptionData(subsData);
        }, error => {
            console.error('Error fetching subscriptions:', error);
        });

        // Cleanup listeners on unmount
        return () => {
            unsubscribeProducts();
            unsubscribeSubscriptions();
        };
    }, []);

    const deleteProduct = async (id) => {
        try {
            await db.collection('subscription_data').doc(id).delete();
            setSubscriptionData(subscriptionData.filter(sub => sub.id !== id));
        } catch (error) {
            console.error('Error deleting subscription:', error);
        }
    };

    const openbulkQt = (subscriptionId, productName) => {
        // Handle the logic for opening bulk quantity settings
    };



    return (
        <>
            <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Subscriptions</h4>
                        <div class="">
                            {subscriptionData.map(({ id, subs }, index) => (
                                <div class="tab-pane" role="tabpanel" aria-labelledby="contact-tab" key={index}>

                                    <div className={`card mb-2 ${subs.status === '1' ? 'custom-green' : 'custom-red'}`}>

                                        <div className="card-body" style={{ backgroundColor: "darkseagreen" }}>
                                            <div class="" style={{ display: "flex", justifyContent: "space-between" }}>
                                                {/* <div style={{ backgroundColor: "", display: "flex" }}> */}
                                                <div class="">
                                                    {/* <p>{subs.product_name}</p> */}
                                                    <img class="rounded" src={productImages[subs.product_name]} alt="product image" style={{ height: "6rem", marginBottom: '10px' }} />
                                                    <h5 class="mt-0" style={{ textAlign: "center", fontSize: "14px" }}>₹ {subs.price}</h5>
                                                </div>
                                                <div>
                                                    <h4 class="mt-0">{subs.product_name}</h4>
                                                    <p style={{ marginTop: '20px' }}>
                                                        {/* <b>Delivered By</b>: {subs.delivered_by} */}
                                                        <b>Subscription ID</b>: {subs.subscription_id}
                                                    </p>
                                                    {/* <p>
                                                                                    <b style={{ marginLeft: '10px' }}>Interval Days</b> : {subs.interval}</p> */}
                                                    <p>
                                                        <b>Start Date</b>: {subs.start_date ? moment(subs.start_date.toDate()).format("DD/MM/YYYY") : ""}
                                                    </p>
                                                    <p>
                                                        <b>End Date</b>: {subs.end_date ? moment(subs.end_date.toDate()).format("MM/DD/YYYY") : "Not Defined"}
                                                    </p>
                                                    {/* <b>Address</b>: {subs.delivering_to} */}

                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                        <div>
                                                            {subs.subscription_type != 'Custom' ?
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-success btn-rounded btn-sm"
                                                                    style={{ color: "white", fontSize: '16px', fontWeight: '500', background: '#4a54ba' }}
                                                                >
                                                                    Quantity : {subs.quantity}
                                                                </button> : (
                                                                    <>
                                                                        <div style={{ display: "flex", marginTop: "1rem", justifyContent: "space-around" }}>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>S</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>M</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>T</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>W</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>T</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>F</div>
                                                                            <div class="badge badge-pill badge-outline-primary" style={{ marginRight: "1rem" }}>S</div>
                                                                        </div>
                                                                        <div style={{ display: "flex", marginTop: "1rem", justifyContent: "space-around" }}>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.sunday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.monday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.tuesday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.wednesday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.thrusday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.friday}</div>
                                                                            <div class="badge badge-pill badge-outline-secondary" style={{ marginRight: "1rem" }}>{subs.saturday}</div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                        <div style={{ marginTop: '15px' }}>
                                                            {subs.subscription_type === "One-Time" ? <></> :

                                                                <button
                                                                    type="button"
                                                                    class="btn btn-success btn-rounded btn-sm"
                                                                    style={{ color: "#fffff", fontSize: '14px', fontWeight: '500', background: '#4a54ba' }}
                                                                >
                                                                    Sub Type : {(subs.subscription_type).toUpperCase()}
                                                                </button>

                                                            }
                                                        </div>
                                                        <div style={{ marginTop: '15px' }}>
                                                            {subs.subscription_type === 'On-Interval' ?
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-success btn-rounded btn-sm"
                                                                    style={{ color: "#fffff", fontSize: '14px', fontWeight: '500', background: '#4a54ba' }}
                                                                >
                                                                    Interval Days : {(subs.interval)}
                                                                </button> : <></>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                <br />

                                                <div>
                                                    <button style={{ padding: "0.2rem 0.85rem" }} class="btn btn-sm" onClick={() => deleteProduct(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "#fff" }}></i></button>
                                                    <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-sm" onClick={() => openbulkQt(subs.subscription_id, subs.product_name)}><i class="menu-icon mdi mdi-settings" style={{ color: "#fff" }}></i></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionDetails;
