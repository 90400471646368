import React from 'react'
import './cart.css';
import './ProductDetail.css';

function AddCart() {
  return (
    <>
        <div class="cart-container">
  <div class="payment_details">
    <h1>Payment Information</h1>
    <div class="details_card">
      <div class="name_address">
        <div class="first_lastName">
          <input type="text" placeholder="First Name" />
          <input type="text" placeholder="Last Name" />
        </div>
        <div class="address">
          <input type="text" onkeyup="change()" id="put" placeholder="Address" />
          <input type="number" placeholder="Pincode" />
          <input type="text" placeholder="Country" />
        </div>
      </div>
      <h1>Shipping Details</h1>
      <div class="shipping_card">
        <div class="new_card">
          <h4>Same as personal</h4>
          <p id="output">Bharat House Bombay Samachar Road</p>
          <p>400001</p>
        </div>
        <div class="add_savedcard">
          <h4>Saved Address</h4>
          <p>Lokhandwala Complex, Andheri (west)</p>
          <p>400053</p>
        </div>
      </div>
      <div className="subscribe-button">
        <a href="" style={{fontSize:'14px' , color:'#ffff' , fontWeight:'bold'}}>Procced to payment</a>
      </div>
    </div>
  </div>
  <div class="order_summary">
    <h1>Order Summary</h1>
    <div class="summary_card">
        {/* {cartItems.map((item, index) => (
            <div class="card_item">
                <div class="product_img">
                    <img
                                    src={item.image}
                                    alt={item.productName}
                                    className="card-img-top"
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                </div>
        <div class="product_info">
          <h1>{item.productName}</h1>
          <p>item.subscriptionType</p>
          <div class="close-btn">
            <i class="fa fa-close"></i>
          </div>
          <div class="product_rate_info">
            <h1>{item.price}</h1>
            <span class="pqt-minus">-</span>
            <span class="pqt">{item.quantity}</span>
            <span class="pqt-plus">+</span>
          </div>
        </div>
      </div>
        ))} */}
      <hr />
      <div class="order_price">
        <p>Order summary</p>
        <h4>$400</h4>
      </div>
      <div class="order_service">
        <p>Additional Service</p>
        <h4>$10</h4>
      </div>
      <div class="order_total">
        <p>Total Amount</p>
        <h4>$370</h4>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default AddCart
