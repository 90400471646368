import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEdit } from '@fortawesome/free-solid-svg-icons';
//import './EditableComponent.css';

const EditableComponent = () => {
  return (
    <div className="editable-container">
         
         
      <button className="edit-button">
        
        <i class="fas fa-edit"></i>
      </button>
      <div className="content">
        <h2>Editable Component</h2>
        <p>This is the content of the component. You can place your editable content here.</p>
      </div>
    </div>
  );
};

export default EditableComponent;
