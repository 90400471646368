import React , {useEffect} from 'react'
import Navbar from './Navbar';
import Footer from "./Footer";

function Ourstory() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  return (
    <>
    
    <div class="our-story-section">
    <div class="white-cow-img">
    <img src="images/white-cow.png"/>
  </div>
  <div>
    <h1 class="heading-story">who we are</h1></div>
    <div class="story-overlay">
    <Navbar/>
    </div>
   
   </div>
<div class="container mt-5 mb-5 p-5">
<div class="story-content text-center">
    <h2>Our Story</h2>
    <p>Growing up on the borders of the city, our founders were always closer to the nature . Passionately fed by their mother with pure wholesome food every day, they became connoisseurs of all that was produced naturally and tasted good. Destiny soon took them to far away nations where they studied and mastered their skills. It was destiny again that brought them back to their motherland. It was then they realized how difficult it had become for people to eat organic food in the cities of India. People were desperately wishing to move towards a healthier way of eating, but there was no one to cater to the need. That’s when their passion for organic food gave birth to a dream, the dream to provide their nation with natural, nutritious, pure and wholesome food.
        Dairy is at the heart of everyone’s diet in India. Milk and all the other dairy products made from it, like butter, yogurt, ice-cream and cheese are the main stay of all that we eat here. So, as a first step to our dream, we have created a 25 acre farm on a beautiful piece of land on the borders of Rajasthan. We call it Whyte Farms.</p>
</div>
</div>
<Footer/>

    </>
  )
}
export default Ourstory

