import React, { useEffect, useState } from 'react';
import db from "../Firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";

const Dialog = ({onClose , subscription}) => {
    const moment = extendMoment(Moment);
    const [showDatePickers, setShowDatePickers] = useState(true);
    const [show_warning, setShowWarning] = useState(false);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [showOnInterval, setShowOnInterval] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [intervalDays, setIntervalDays] = useState(2);
    const [nextDeliveryDate, setNextDeliveryDate] = useState('');
    const [activeFrequency, setActiveFrequency] = useState();
    const [quantity, setQuantity] = useState(1);
    const [subscriptionType, setSubscriptionType] = useState();
    const [product, setProduct] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [customDays, setCustomDays] = useState({
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0
    });

    const incrementQuantity = () => setQuantity(quantity + 1);
    const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    const incrementInterval = () => setIntervalDays(intervalDays + 1);
    const decrementInterval = () => setIntervalDays(intervalDays > 2 ? intervalDays - 1 : 2);

    const fetchProductDetails = async () => {
        try {
            const doc = await db.collection('products_data')
            .where('product_name' , '==' , subscription.product_name)
            .get();

            if (doc.exists) {
                const productData = doc.data();
                setProduct(productData);
                setSelectedPackage(productData.packagingOptions[0]);
            } else {
                
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    };
    
    useEffect(()=> {
        fetchProductDetails();
        setActiveFrequency(subscription.subscription_type);
        setQuantity(subscription.quantity);
        if(subscription.subscription_type == 'On-Interval') {
            setIntervalDays(subscription.interval);
            setShowOnInterval(true);
        }else if(subscription.subscription_type == 'Custom') {
            setShowCustom(true);
            Object.keys(customDays).forEach((day) => {
                if (subscription[day] !== 0) {
                  setCustomDays(prevState => ({
                    ...prevState,
                    [day]: subscription[day]
                  }));
                }
              });
        }else {
            setIntervalDays(subscription.interval);
        }
        setStartDate(new Date(subscription.start_date));
        setEndDate(new Date(subscription.end_date));
        const checkTime = () => {
            const currentTime = new Date();
            const currentHour = currentTime.getHours();
            if (currentHour >= 23) {
              setShowWarning(true);
            } else {
              setShowWarning(false);
            }
          };
      
          checkTime();
        //setShowDatePickers(subscription.subscription_type !== "One-Time");
    },[subscription]);

    

    const handleFrequencyClick = (frequency) => {
        if(frequency == "Everyday") {
            setIntervalDays(1);
        }else if(frequency == "On-Interval") {
            setIntervalDays(2);
        }
        setActiveFrequency(frequency);
        setSubscriptionType(frequency);
        //setShowDatePickers(frequency !== "One-Time");
        setShowOnInterval(frequency === "On-Interval");
        setShowCustom(frequency === "Custom");
    };

    const handleCustomDayChange = (day, value) => {
        setCustomDays(prevState => ({
            ...prevState,
            [day]: value
        }));
    };

    const updateSubscription = async () => {
        try {
            const today = new Date();
            const updatedDate = new Date(today);
            let new_next_delivery_date = "";
           // tomorrow.setDate(tomorrow.getDate() + 1);
            if(activeFrequency == 'Everyday') {
                new_next_delivery_date = moment(updatedDate.setDate(updatedDate.getDate() + 1)).format('YYYY-MM-DD');
                setIntervalDays(1);
            }else if(activeFrequency == 'On-Interval'){
                new_next_delivery_date = moment(updatedDate.setDate(updatedDate.getDate() + intervalDays)).format('YYYY-MM-DD');
            }else {
               setQuantity(0);
               setIntervalDays(0);
            }
            console.log('new_next_delivery_date', new_next_delivery_date);

             await db.collection('subscriptions_data').doc(subscription.id).update({
                   subscription_type: activeFrequency,
                   quantity: parseInt(quantity),
                   sunday:  activeFrequency == "Custom" ? parseInt(customDays.sunday)  : 0,
                   monday:  activeFrequency == "Custom" ? parseInt(customDays.monday)  : 0,
                   tuesday: activeFrequency == "Custom" ? parseInt(customDays.tuesday) : 0,
                   wednesday: activeFrequency == "Custom" ? parseInt(customDays.wednesday) : 0,
                   thursday: activeFrequency == "Custom" ? parseInt(customDays.thursday) : 0,
                   friday: activeFrequency == "Custom" ? parseInt(customDays.friday) : 0,
                   saturday: activeFrequency == "Custom" ? parseInt(customDays.saturday) : 0,
                   interval: parseInt(intervalDays),
                   updated_date: new Date(),
                   reason: ""
                 });
            
            onClose();
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

  return (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <div className="dialog-header">Edit Subscription</div>
    <div className="dialog-content">
            <main>
            <div className="product-detail-container">
                    <div className="product-detail-card">
                        <div className="product-detail-left">
                            <img 
                            src={`images/${subscription.product_name.replace(/\\/g, '/').replace(/ /g, '%20')}.png`}
                            className="img-fluid rounded-start product-detail-page-image" alt={subscription.product_name}
                            //  style={{ height: "30rem" }}
                              />
                        </div>
                        <div className="product-detail-right">
                            <h2>{subscription.product_name}</h2>
                            <p>Frequently</p>
                            <div className="frequency-buttons">
                                {['Everyday', 'Custom', 'On-Interval'].map(frequency => (
                                    <button
                                        key={frequency}
                                        className={`frequency-button ${activeFrequency === frequency ? 'active' : ''}`}
                                        onClick={() => handleFrequencyClick(frequency)}
                                    >
                                        {frequency}
                                    </button>
                                ))}
                            </div>
                            <div className="quantity-content">
  {!showCustom && (
    <div className="quantity-wrapper">
      <span className="quantity-title theme-color">
        Quantity
      </span>
      <div className="quantity-selector">
        <button onClick={decrementQuantity}>-</button>
        <span className="quantity-value">{quantity}</span>
        <button onClick={incrementQuantity}>+</button>
      </div>
    </div>
  )}

  {showOnInterval && (
    <div className="interval-wrapper">
      <span className="interval-separator theme-color">
        |
      </span>
      <span className="interval-title theme-color">
        Every
      </span>
      <div className="interval-selector">
        <button onClick={decrementInterval}>-</button>
        <span className="interval-value">{intervalDays}</span>
        <button onClick={incrementInterval}>+</button>
      </div>
      <p className="interval-days theme-color">Day(s)</p>
    </div>
  )}
</div>

                            {showCustom && (
                                <div className="custom-days" style={{ display: "ruby-text" }}>
                                    {Object.keys(customDays).map((day) => (
                                        <div key={day} className="form-group">
                                            <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={day}
                                                value={customDays[day]}
                                                onChange={(e) => handleCustomDayChange(day, e.target.value === '' ? '1' : e.target.value)}
                                                min="0"
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        handleCustomDayChange(day, '1');
                                                    }
                                                }}
                                                
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Started On</h5>
                                        <input type="date" className="form-control" value={moment(subscription.start_date.toDate()).format('YYYY-MM-DD')}  disabled/>
                                    </div>
                    
                                </div>
                                <div>
                                    {show_warning && (
                                        <p className="warning-text">
                                            Please note that this update will take effect from {moment(new Date().setDate(new Date().getDate() + 2)).format('YYYY-MM-DD')}
                                        </p>
                                    )}
                                </div>
                                <div className="dialog-footer">
            <button className="subscribe-button" style={{backgroundColor:'black' , marginRight:'1rem'}} onClick={updateSubscription}>Update</button>
            <button className="subscribe-button" onClick={onClose}>Close</button>
        </div>
                            </div>
                    </div>
                </div>
            </main>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
