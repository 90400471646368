import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const OrderSuccess = () => {

    return (
        <>
        <Navbar />
            <div class="header-details" style={{ height: '100px', background: 'black' }}></div>
            <div style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                <div className="text-center">
                    <h2>Your order has been successfully placed!</h2>
                    <p>Thank you for your purchase.</p>
                    <p>Please click <a href="/profile">here</a> to go back to your profile.</p>
                </div>
            </div>
        <Footer />
        </>
    );
};

export default OrderSuccess;
